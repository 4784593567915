import MicroModal from 'micromodal';

export default () => {
    MicroModal.init({
        onShow: () => $('body').addClass('l-body--modal-open'),
        onClose: () => $('body').removeClass('l-body--modal-open'),
        disableScroll: true,
        awaitOpenAnimation: false,
        awaitCloseAnimation: true,
    });
};
