function taxonomiesListAccordeon() {
    const lists = document.querySelectorAll(
        `[data-role='taxonomies-list-accordeon']`,
    );
    [...lists].map(list => {
        list.addEventListener('click', evt => {
            const target = evt.target;
            const listItem = list.querySelector(
                `[data-role='taxonomies-list-list']`,
            );
            const listItemArrow = list.querySelector(
                `[data-role='taxonomies-list-arrow']`,
            );
            if (
                !target.closest(`[data-role='taxonomies-list-toggler']`) ||
                !listItem ||
                !listItemArrow
            ) {
                return;
            }

            evt.preventDefault();
            const isActive = listItem.getAttribute('data-active') === '1';
            if (isActive) {
                listItemArrow.classList.remove('rotate-180');
                listItem.setAttribute('data-active', 0);
            } else {
                listItemArrow.classList.add('rotate-180');
                listItem.setAttribute('data-active', 1);
            }
            $(listItem).slideToggle(); // eslint-disable-line
        });
    });
}

export default taxonomiesListAccordeon;
