/* eslint-disable */

/*
 * Search Toggle
 *
 * runs the functionality of clicking a search icon and a search bar appears
 */

function searchBar() {
    // Search Toggle
    $('.js-header-search-toggle').on('click', function() {
        $('.m-search-bar').toggleClass('m-search-bar--active');
        $('body').toggleClass('h-scroll-lock');

        if ($('.algolia-autocomplete').css('display') == 'block') {
            $('.algolia-autocomplete').css('display', 'none');
        }

        if ($('.m-search-bar').hasClass('m-search-bar--active')) {
            setTimeout(function() {
                $('.m-search-bar__search-field').focus();
            }, 600);
        }
    });

    $('.js-header-search-toggle-mobile').on('click', function() {
        $('.js-slide-out-search-wrapper-mob').toggleClass(
            'c-slide-out__search-wrapper--active',
        );
        setTimeout(function() {
            $('.js-search-field-mob').focus();
        }, 600);
    });

    /*
     * Search Box + Popular Searches Dropdown
     */
    const searchFields = document.querySelectorAll(
        '.m-search-bar__search-field',
    );
    if (searchFields.length) {
        [...searchFields].map(field => {
            const parent = field.closest('.m-search-bar');
            if (!parent) {
                return false;
            }
            const popularSearchesBox = parent.querySelector(
                '.popular-searches',
            );
            if (!popularSearchesBox) {
                return false;
            }

            field.addEventListener('focusin', event => {
                if (field.value.length) {
                    popularSearchesBox.style.display = 'none';
                } else {
                    popularSearchesBox.style.display = 'block';
                }
            });
            field.addEventListener('focusout', event => {
                popularSearchesBox.style.display = 'none';
            });
            field.addEventListener('keyup', event => {
                if (field.value.length) {
                    popularSearchesBox.style.display = 'none';
                } else {
                    popularSearchesBox.style.display = 'block';
                }
            });
        });
    }
}

export default searchBar;
