import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function sliderLatestPostsB() {
    Swiper.use([Autoplay, Navigation, Pagination]);

    // Ensuring we don't show buttons if there is just 1 entry outputted
    if ($('.o-latest-posts-b-1__slide-item ').length <= 1) {
        $('.o-latest-posts-b-1__slider-wrapper').addClass(
            'o-latest-posts-b-1__slider-wrapper--disabled',
        );
    } else {
        $('.o-latest-posts-b-1__slider-wrapper').removeClass(
            'o-latest-posts-b-1__slider-wrapper--disabled',
        );

        new Swiper('.js-latest-posts-b', {
            loop: false,
            // speed: 600,
            // autoplay: {
            //     delay: 7000,
            // },
            slidesPerView: 1,
            spaceBetween: 50,
            breakpoints: {
                1300: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                },
            },
            pagination: {
                el: '.o-latest-posts-b-1__slider-pagination',
                type: 'bullets',
                clickable: true,
            },
        });
    }
}

export default sliderLatestPostsB;
