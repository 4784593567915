import Swiper, { Controller, EffectFade, Navigation } from 'swiper';

function sliderTestimonialsC() {
    Swiper.use([Navigation, EffectFade, Controller]);

    $('[data-module="sliderTestimonialsC"]').each(function() {
        const swiper_thumbs = new Swiper(
            $(this).find('[data-slider-thumbs]')[0],
            {
                loop: true,
                speed: 600,
                slidesPerView: 2,
                spaceBetween: 15,
                allowTouchMove: true,
                navigation: {
                    prevEl: $(this).find('.js-slider-prev')[0],
                    nextEl: $(this).find('.js-slider-next')[0],
                },
            },
        );

        const swiper = new Swiper($(this).find('[data-slider-main]')[0], {
            loop: true,
            speed: 600,
            slidesPerView: 1,
            spaceBetween: 30,
            allowTouchMove: false,

            effect: 'fade',
            fadeEffect: { crossFade: true },
        });

        /*swiper.controller.control = swiper_thumbs;
        swiper_thumbs.controller.control = swiper;*/

        swiper_thumbs.on('slideChange', function() {
            swiper.slideToLoop(swiper_thumbs.realIndex);
        });
    });
}

export default sliderTestimonialsC;
