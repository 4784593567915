/*
 * hamburger
 *
 * runs the functionality of clicking the hamburger icon in the top nav
 */

let selector = '.js-hamburger';
let activeClass = 'is-active';
let bodyActiveClass = 'h-scroll-lock nav-active';
let headerActiveClass = 'o-header--hamburger-active';
let navMenu = '.js-slide-out';
let navMenuActiveClass = 'm-slide-out--active';
let headerSearchButton = 'o-header__search-button';
let searchBar = 'm-search-bar';

export function hamburger() {
    $(selector).click(function(e) {
        e.preventDefault();
        $(selector).toggleClass(activeClass);

        // Ensuring body cannot be scrolled up and down when the mobile nav is shown
        $('.js-body').toggleClass(bodyActiveClass);
        $('.js-header').toggleClass(headerActiveClass);

        // Makes slide-out appear
        $(navMenu).toggleClass(navMenuActiveClass);

        $(`.${headerSearchButton}`).toggleClass(`${headerSearchButton}--anim`);

        if ($(`.${searchBar}`).hasClass(`${searchBar}--active`)) {
            $(selector)
                .parent()
                .siblings(headerSearchButton)
                .trigger('click');
        }
    });

    $(window).on('resize', function() {
        if ($(window).width() >= 1280) {
            if ($('.header__hamburger').hasClass('is-active')) {
                $('.header__hamburger').trigger('click');
            }
        }
    });
}

export function hamburgerClose() {
    $(selector).removeClass(activeClass);
    $('.js-body').removeClass(bodyActiveClass);
    $('.js-header').removeClass(headerActiveClass);
    $(navMenu).removeClass(navMenuActiveClass);
}
