import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

function sliderStorytellingB() {
    Swiper.use([Autoplay, Navigation, Pagination]);

    // Ensuring we don't show buttons if there is just 1 entry outputted
    if ($('.o-storytelling-b-1__slide-item ').length <= 1) {
        $('.o-storytelling-b-1__slider-wrapper').addClass(
            'o-storytelling-b-1__slider-wrapper--disabled',
        );

        $('.js-slider-storytelling-b-btn-prev').addClass(
            'js-slider-storytelling-b-btn-prev--disabled',
        );
        $('.js-slider-storytelling-b-btn-next').addClass(
            'js-slider-storytelling-b-btn-next--disabled',
        );
    } else {
        $('.o-storytelling-b-1__slider-wrapper').removeClass(
            'o-storytelling-b-1__slider-wrapper--disabled',
        );

        $('.js-slider-storytelling-b-btn-prev').removeClass(
            'js-slider-storytelling-b-btn-prev--disabled',
        );
        $('.js-slider-storytelling-b-btn-next').removeClass(
            'js-slider-storytelling-b-btn-next--disabled',
        );

        new Swiper('.js-slider-storytelling-b', {
            loop: false,
            speed: 600,
            // autoplay: {
            //     delay: 7000,
            // },
            slidesPerView: 1,
            spaceBetween: 30,
            breakpoints: {
                1300: {
                    slidesPerView: 3,
                    spaceBetween: 70,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                },
            },
            navigation: {
                prevEl: '.js-slider-storytelling-b-btn-prev',
                nextEl: '.js-slider-storytelling-b-btn-next',
            },
            pagination: {
                el: '.o-storytelling-b-1__slider-pagination',
                type: 'bullets',
                clickable: true,
            },
        });
    }
}

export default sliderStorytellingB;
