import Swiper, {
    Autoplay,
    Controller,
    EffectCube,
    EffectFade,
    Navigation,
    Pagination,
} from 'swiper';

function cards1() {
    Swiper.use([
        Autoplay,
        Navigation,
        Pagination,
        EffectCube,
        EffectFade,
        Controller,
    ]);

    $('[data-module="cards1"]').each(function() {
        new Swiper($(this)[0], {
            loop: true,
            direction: 'vertical',
            effect: 'cube',
            speed: 750,
            allowTouchMove: false,

            cubeEffect: {
                shadow: false,
                slideShadows: false,
            },

            autoplay: {
                delay: 2000,
                pauseOnMouseEnter: true,
            },

            on: {
                afterInit: function(e) {
                    startAnimation(e);
                },
                slideChangeTransitionEnd: function(e) {
                    startAnimation(e);
                },
            },
        });
    });

    function startAnimation(e) {
        $(e.$el)
            .find('.swiper-slide')
            .removeClass('o-cards-1__title-slides-slide--active');

        $(e.$el)
            .find('.swiper-slide-active')
            .addClass('o-cards-1__title-slides-slide--active');
    }
}

export default cards1;
