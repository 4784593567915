class globalLoaderClass {
    constructor() {
        // Loader element itself
        this.loaderElement = null;
        // Default class of the loader
        this.defaultClass = 'a-global-loader';
        // Class that makes loader visible
        this.activeClass = `${this.defaultClass}--active`;

        // Set loader
        this._initLoader();
    }

    /**
     * Set the loader as the Class property
     * @private
     */
    _initLoader() {
        this.loaderElement = document.querySelector(
            `[data-role='global-loader']`,
        );
    }

    /**
     * Making Loader visible
     * @public
     */
    showLoader() {
        if (!this.loaderElement) {
            console.log('Error: No global loader found');
            return;
        }

        this.loaderElement.classList.add(this.activeClass);
    }

    /**
     * Hiding Loader
     * @public
     */
    hideLoader() {
        if (!this.loaderElement) {
            console.log('Error: No global loader found');
            return;
        }

        this.loaderElement.classList.remove(this.activeClass);
    }
}

function globalLoader() {
    return new globalLoaderClass();
}

export default globalLoader;
