import './util/modernizr';
import './util/polyfills';
import '../style.scss';
import './core/core';

/*
 * Atoms
 */
import floatingCta from '../patterns/01-atoms/buttons/button-floating-cta/button-floating-cta';
import scrollToTop from '../patterns/01-atoms/misc/scroll-to-top/scroll-to-top';
import globalLoader from '../patterns/01-atoms/global-loader/global-loader';
window.globalLoader = globalLoader();
/*
 * Molecules
 */
import showAccordion from '../patterns/02-molecules/accordions/accordion/accordion';
import debugControls from '../patterns/02-molecules/admin/debug-controls/debug-controls';
import dropdown from '../patterns/02-molecules/dropdowns/dropdown/dropdown';
import gallery from '../patterns/02-molecules/galleries/gallery/gallery';
import mapBlock from '../patterns/02-molecules/maps/map/map';
import modal from '../patterns/02-molecules/modals/modal/modal';
import { hamburger } from '../patterns/02-molecules/nav/hamburger/hamburger';
import navigateCategory from '../patterns/02-molecules/nav/nav-category/nav-category';
import navMob from '../patterns/02-molecules/nav/nav-mobile/nav-mobile';
import searchBar from '../patterns/02-molecules/search/search-bar/search-bar';
import { slideOut } from '../patterns/02-molecules/slide-outs/slide-out/slide-out';
import sliderImages from '../patterns/02-molecules/sliders/slider-images/slider-images';
import sliderTestimonials from '../patterns/02-molecules/sliders/slider-testimonials/slider-testimonials';
import sliderTestimonialsC from '../patterns/02-molecules/sliders/slider-testimonials-c/slider-testimonials-c';
import socialShare from '../patterns/02-molecules/social/social-share/social-share';
import socialShare2 from '../patterns/02-molecules/social/social-share-2/social-share-2';
import tabs from '../patterns/02-molecules/tabs/tabs/tabs';
import multiselectDropdown from '../patterns/02-molecules/dropdowns/terms-multiselect/terms-multiselect';
import navDropdown from '../patterns/02-molecules/nav/nav-dropdown/nav-dropdown';
import taxonomiesListAccordeon from '../patterns/02-molecules/lists/taxonomies-list/taxonomies-list';

/*
 * Organisms
 */
import header from '../patterns/03-organisms/header/header-1/header-1';
import parallaxScroll from './animations/parallax-light';
import filterType1 from '../patterns/03-organisms/repeatable-blocks/filter/filter-1/filter-1';
import filterType2 from '../patterns/03-organisms/repeatable-blocks/filter/filter-2/filter-2';
import sliderTestimonialsB from '../patterns/03-organisms/repeatable-blocks/testimonials-b/testimonials-b-1/testimonials-b-1';
import sliderStorytellingB from '../patterns/03-organisms/repeatable-blocks/storytelling-b/storytelling-b-1/storytelling-b-1';
import sliderLatestPostsB from '../patterns/03-organisms/repeatable-blocks/latest-posts-b/latest-posts-b-1/latest-posts-b-1';
import cards1 from '../patterns/03-organisms/repeatable-blocks/cards/cards-1/cards-1';
/*
 * Animations
 */
// import scrollSkewer from './animations/scroll-skewer';
import letterEffects from './animations/splittext';
import cursorEffects from './animations/cursor-effects';
/*
 * Misc
 */
import scrollToAnchor from './misc/scroll/scroll-to-anchor';
import slider1 from '../patterns/02-molecules/sliders/slider-1/slider-1';

/*
 * Plop Entry (do not remove)
 */
////DO NOT ERASE OR PLOP IMPORTING WILL FAIL
////IMPORT FROM PLOP

/*
 * Module Caller
 *
 * functions below are only run if the component exists (verified through a "data-module" in the html)
 * in the ajax container or page. Barba.js instantiates the functions below on container reload as well.
 *
 * Place in alphabetical order
 */
const modules = {
    showAccordion,
    sliderImages,
    sliderTestimonials,
    sliderTestimonialsB,
    sliderTestimonialsC,
    sliderStorytellingB,
    sliderLatestPostsB,
    cards1,
    header,
    navigateCategory,
    searchBar,
    scrollToTop,
    floatingCta,
    socialShare,
    socialShare2,
    hamburger,
    slideOut,
    modal,
    debugControls,
    tabs,
    mapBlock,
    dropdown,
    gallery,
    filterType1,
    filterType2,
    multiselectDropdown,
    navDropdown,
    taxonomiesListAccordeon,
};

/*
 * Fire JavaScript functions by if the associated "data-module" exists on the page.
 */
let calledModulesNames = [];
modules.instantiate = function(elem) {
    const $this = $(elem);
    const module = $this.attr('data-module');

    if (module === undefined) {
        throw 'Module not defined (use data-module="")';
    } else if (module in modules) {
        if ($this.attr('data-initialized') === 'true') {
            return;
        }
        new modules[module](elem);
        $this.attr('data-initialized', true);
    } else {
        throw "Module '" + module + "' not found";
    }
};

jQuery(document).ready(function() {
    scrollToAnchor($);
    navMob($);

    /*
     * Loops through component functions that should only run if the component exists in the ajax container or page.
     * The actual check is performed through "data-module";
     */
    $('[data-module]').each(function() {
        if ($.inArray($(this).data('module'), calledModulesNames) == -1) {
            modules.instantiate(this);
            calledModulesNames.push($(this).data('module'));
        }
    });

    /*
     * GSAP based animations. Hide if not used.
     */
    // cursorEffects($);
    // letterEffects($);
    // scrollSkewer($);
});

jQuery(window).scroll(function() {
    parallaxScroll($);
});
