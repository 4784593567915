function showAccordion() {
    $('.js-accordion').click(function(e) {
        e.preventDefault();

        $(this)
            .parent()
            .parent()
            .toggleClass('is-active');
        // eslint-disable-next-line jquery/no-slide
        $(this)
            .parent()
            .siblings('.m-accordion__group-inner')
            .find('.m-accordion__text')
            .slideToggle('fast');
    });

    $('.m-accordion__group-inner').on('click', function() {
        $(this)
            .siblings('.m-accordion__track')
            .find('.js-accordion')
            .trigger('click');
    });
}

export default showAccordion;
