class FilterTypeOne {
    constructor(block) {
        this.container = block;

        this._initProps();
        this._initElements();
        this._initStates();
        this._initListeners();
    }

    /**
     * All needed parameters of the block are set up here
     *
     * @private
     */
    _initProps() {
        // Main organism's class
        this.componentClass = 'o-filter-1';

        // Block Top offset
        this.blockOffsetTop = 70;

        // Data from backend
        // URL for ajax requests
        // eslint-disable-next-line no-undef
        this.ajaxUrl = filterTypeOne.url;
        // Name of the action
        // eslint-disable-next-line no-undef
        this.ajaxAction = filterTypeOne.action;
        // Nonce code for ajax requests
        // eslint-disable-next-line no-undef
        this.ajaxNonce = filterTypeOne.nonce;

        // Current data of the filters form
        this.formData = {};

        // Are we currently waiting for AJAX response?
        this.isProcessingAjax = false;

        // UI
        this.labelActiveClasses = [
            'text-brand-color-2-d',
            'hover:text-brand-color-2-h',
        ];
        this.labelInactiveClasses = [
            'text-brand-color-2-h',
            'hover:text-brand-color-2-d',
        ];
    }

    /**
     * All DOM elements within the main Block which we are going to use are set up here
     *
     * @private
     */
    _initElements() {
        // Form
        this.filtersForm = this.container.querySelector(
            `[data-role='filters-form']`,
        );
        // Search Input
        this.searchInput = this.container.querySelector(`input[name='search']`);
        // Taxonomies List
        this.termsLists = this.container.querySelectorAll(
            `[data-role='taxonomies-list']`,
        );
        // Submit Button
        this.submitButton = this.container.querySelector(
            `[data-role='submit-form']`,
        );
        // The main container of posts
        this.filterList = this.container.querySelector(
            `[data-role='posts-list']`,
        );
        // Page Input
        this.pageInput = this.container.querySelector(`[name='page']`);
        // Is Pagination input. Helps to distinguish pagination query from other queries
        this.paginationInput = this.container.querySelector(
            `[name='isPagination']`,
        );
        // Pagination Container
        this.paginationBlock = this.container.querySelector(
            `[data-role='pagination-block']`,
        );
        // Total posts count input
        this.totalPostsCountInput = this.container.querySelector(
            `[name='totalPostsCount']`,
        );
    }

    /**
     * Set certain states for elements on class init
     *
     * @private
     */
    _initStates() {
        // Update the initial form data
        this._updateFormData();
    }

    /**
     * All events listeners are listed here
     *
     * @private
     */
    _initListeners() {
        // Form Submit button
        if (this.submitButton) {
            this.submitButton.addEventListener(
                'click',
                this._submitButtonHandler.bind(this),
            );
        }
        // Form submit action
        if (this.filtersForm) {
            this.filtersForm.addEventListener(
                'submit',
                this._submitButtonHandler.bind(this),
            );
        }
        // Terms List Functionality
        if (this.termsLists.length) {
            [...this.termsLists].map(termsListContainer => {
                const inputs = termsListContainer.querySelectorAll(
                    'input[type="radio"]',
                );
                if (!inputs.length) {
                    return false;
                }
                inputs.forEach(input => {
                    input.addEventListener(
                        'change',
                        this._termsListHandler.bind(this),
                    );
                });
            });
        }
        // Pagination
        if (this.paginationBlock) {
            this.paginationBlock.addEventListener(
                'click',
                this._paginationHandler.bind(this),
            );
        }
    }

    /**
     * Form Submit button click event handler
     *
     * @param event
     * @private
     */
    _submitButtonHandler(event) {
        // Preventing default behaviour
        event.preventDefault();

        // On form submitting we need to see the first page
        this.pageInput.value = 1;
        // Sending AJAX request
        this._requestPosts();
    }

    /**
     * Terms List handler
     *
     * @param event
     * @private
     */
    _termsListHandler(event) {
        // Do nothing when AJAX is processing
        if (this.isProcessingAjax) {
            event.preventDefault();
            return;
        }

        // Related Input
        const input = event.target;
        // Checked state after change
        const isChecked = input.checked;
        // Terms List container
        const container = input.closest(`[data-role='taxonomies-list']`);

        // Make the rest options Unchecked
        this._unselectAllOptionsState(container);

        // Otherwise change UI for the current pill according to its new value
        this._updateLabelState(input, isChecked);

        this.pageInput.value = 1;
        // Sending AJAX request for getting posts
        this._requestPosts();
    }

    /**
     * Update all the inputs' labels within a passed container, makes it unchecked
     *
     * @param container
     * @private
     */
    _unselectAllOptionsState(container) {
        if (!container) {
            return;
        }
        // All the checkbox that are need to be updated
        const inputs = container.querySelectorAll(`input[type='radio']`);
        [...inputs].map(input => {
            this._updateLabelState(input, false);
        });
    }

    /**
     * Changing a label UI according to it's state (active or not)
     *
     * @param input
     * @param isChecked
     * @private
     */
    _updateLabelState(input, isChecked = true) {
        if (!input) {
            return;
        }
        const label = input.closest('label');
        if (!label) {
            return;
        }
        if (isChecked) {
            label.classList.remove(...this.labelInactiveClasses);
            label.classList.add(...this.labelActiveClasses);
        } else {
            label.classList.remove(...this.labelActiveClasses);
            label.classList.add(...this.labelInactiveClasses);
        }
    }

    /**
     * Collect the current form data and store them in the Class's parameter
     *
     * @private
     */
    _updateFormData() {
        let formData = new FormData(this.filtersForm);
        formData.append('action', this.ajaxAction);
        formData.append('_nonce', this.ajaxNonce);

        // For dealing with multiselect values we need to filter it
        const data = {};
        formData.forEach(function(value, key) {
            const prev = data[key];
            if (prev) {
                data[key] = `${prev}, ${value}`;
            } else {
                data[key] = value;
            }
        });

        this.formData = data;
    }

    /**
     * Sending AJAX request with parameters for getting posts list output
     *
     * @private
     */
    _requestPosts() {
        // Update current form data before sending it to backend
        this._updateFormData();

        // eslint-disable-next-line jquery/no-ajax
        $.ajax({
            type: 'POST',
            url: this.ajaxUrl,
            data: this.formData,
            beforeSend: () => {
                this.isProcessingAjax = true;
                // Show loader
                this._updateLoaderState();
            },
            success: response => {
                // New output from backend (posts list)
                let postsListOutput = response.data.postsOutput;
                if (!postsListOutput) {
                    return;
                }
                // Update the whole posts list container
                this.filterList.innerHTML = postsListOutput;

                // Updating the total posts count list
                if (
                    response.data.totalPostsCount !== undefined &&
                    this.totalPostsCountInput
                ) {
                    this.totalPostsCountInput.value = parseInt(
                        response.data.totalPostsCount,
                    );
                }

                if (!this.paginationBlock) {
                    return;
                }
                // Pagination Block
                let paginationBlockOutput = response.data.paginationBlockOutput;
                if (!paginationBlockOutput) {
                    return;
                }

                // Update the whole pagination block container
                this.paginationBlock.innerHTML = paginationBlockOutput;
            },
            // eslint-disable-next-line no-unused-vars
            complete: xhr => {
                this.isProcessingAjax = false;
                // Hide loader
                this._updateLoaderState(false);
                if (this.paginationInput) {
                    this.paginationInput.value = 0;
                }
                // Scroll to the top of the block
                this._scrollToBlockTop();
            },
            error: xhr => {
                console.log('error:', xhr);
            },
        });
    }

    /**
     * Show Loader above the main filter block. show = true - show loader; show = false - hide loader
     *
     * @param show
     * @private
     */
    _updateLoaderState(show = true) {
        if (show) {
            this.container.classList.add('opacity-50', 'pointer-events-none');
        } else {
            this.container.classList.remove(
                'opacity-50',
                'pointer-events-none',
            );
        }
    }

    /**
     * Pagination functionality
     *
     * @param event
     * @private
     */
    _paginationHandler(event) {
        event.preventDefault();

        const target = event.target;
        const button = target.closest(`[data-role='pagination-button']`);
        if (!button) {
            return;
        }

        const page = parseInt(button.getAttribute('data-value'));
        if (isNaN(page)) {
            return;
        }

        this.pageInput.value = page;
        if (this.paginationInput) {
            this.paginationInput.value = 1;
        }
        this._requestPosts();
    }

    /**
     * Smooth Scrolling to the top of the block. Better to use in mobile mode
     * @private
     */
    _scrollToBlockTop() {
        let bodyOffset = document.body.getBoundingClientRect().top;
        let blockOffset = this.container.getBoundingClientRect().top;

        let neededOffset = blockOffset - bodyOffset - this.blockOffsetTop;
        neededOffset = neededOffset > 0 ? neededOffset : 0;

        window.scrollTo({
            top: neededOffset,
            behavior: 'smooth',
        });
    }
}

function filterType1() {
    const filterBlocks = document.querySelectorAll(
        `[data-role='filter-block-1']`,
    );
    if (!filterBlocks.length) {
        return;
    }
    filterBlocks.forEach(block => {
        new FilterTypeOne(block);
    });
}

export default filterType1;
